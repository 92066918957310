
import { defineComponent, reactive, ref } from "vue";
import { getManager } from "@/core/services/api/manager";
import localstorage from "@/core/services/LocalstorageService";
import { filterTableDataBySearch } from "@/core/utils/tableSearch";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getFilePath } from "@/core/utils/file";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader: any = reactive([
      { key: "avatar", name: "頭像" },
      { key: "name", name: "姓名", sortable: true },
      { key: "email", name: "Email", sortable: true },
    ]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const isLoadind = ref(false);
    // 搜尋輸入值
    const search = ref<string>("");

    // 取得空間管理者
    const handleGetManager = async () => {
      return await getManager(currentSpaceGuid);
    };

    // 設置列表資料
    const setTableData = async () => {
      isLoadind.value = true;
      let managers: any = await handleGetManager();
      tableData.splice(0);
      for (const manager of managers) {
        tableData.push({
          guid: manager.simpleUser.guid,
          name: manager.simpleUser.name,
          email: manager.simpleUser.email,
          avatar: manager.simpleUser.theme.avatar,
        });
      }
      console.log("tableData", tableData);
      initTableData.splice(0, initTableData.length, ...tableData);
      isLoadind.value = false;
    };

    // 根據搜尋設置列表資料
    const handleFilterTableDataBySearch = () => {
      filterTableDataBySearch(tableData, initTableData, search.value);
    };

    // 取得照片
    const getImage = (guid) => {
      if (guid !== "" && guid !== null) {
        return getFilePath(guid);
      } else {
        return "";
      }
    };

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      isLoadind,
      search,
      handleFilterTableDataBySearch,
      getImage,
    };
  },
});
