<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col d-flex">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="handleFilterTableDataBySearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
      </div>
      <div class="row">
        <div
          class="
          col-6
            notice
            d-flex
            bg-light-warning
            rounded
            border-warning border border-dashed
            mb-10
            p-6
            mt-3
          "
        >
          <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
            <inline-svg src="media/icons/duotune/general/gen044.svg" />
          </span>
          <!--begin::Wrapper-->
          <div class="d-flex flex-stack flex-grow-1">
            <!--begin::Content-->
            <div class="fw-bold">
              <h4 class="text-gray-800 fw-bolder" style="line-height:30px">若要新增管理員，請將欲新增使用者資料寄至<a href="mailto:service@spacesgo.com">service@spacesgo.com</a><br>我們將盡快為您處理!</h4>
              <!-- <div class="fs-6 text-gray-600">
                Adding new API key may afftect to your
                <a href="#">Affiliate Income</a>
              </div> -->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Wrapper-->
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-avatar="{ row: tableData }">
          <div class="symbol symbol-55px symbol-circle">
            <img
              v-if="tableData.avatar"
              :src="getImage(tableData.avatar)"
              alt="image"
            />
            <img v-else src="media/avatars/blank.png" alt="image" />
          </div>
        </template>
        <template v-slot:cell-name="{ row: tableData }">
          {{ tableData.name }}
        </template>
        <template v-slot:cell-email="{ row: tableData }">
          {{ tableData.email }}
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import { getManager } from "@/core/services/api/manager";
import localstorage from "@/core/services/LocalstorageService";
import { filterTableDataBySearch } from "@/core/utils/tableSearch";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getFilePath } from "@/core/utils/file";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader: any = reactive([
      { key: "avatar", name: "頭像" },
      { key: "name", name: "姓名", sortable: true },
      { key: "email", name: "Email", sortable: true },
    ]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const isLoadind = ref(false);
    // 搜尋輸入值
    const search = ref<string>("");

    // 取得空間管理者
    const handleGetManager = async () => {
      return await getManager(currentSpaceGuid);
    };

    // 設置列表資料
    const setTableData = async () => {
      isLoadind.value = true;
      let managers: any = await handleGetManager();
      tableData.splice(0);
      for (const manager of managers) {
        tableData.push({
          guid: manager.simpleUser.guid,
          name: manager.simpleUser.name,
          email: manager.simpleUser.email,
          avatar: manager.simpleUser.theme.avatar,
        });
      }
      console.log("tableData", tableData);
      initTableData.splice(0, initTableData.length, ...tableData);
      isLoadind.value = false;
    };

    // 根據搜尋設置列表資料
    const handleFilterTableDataBySearch = () => {
      filterTableDataBySearch(tableData, initTableData, search.value);
    };

    // 取得照片
    const getImage = (guid) => {
      if (guid !== "" && guid !== null) {
        return getFilePath(guid);
      } else {
        return "";
      }
    };

    const init = async () => {
      await setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      isLoadind,
      search,
      handleFilterTableDataBySearch,
      getImage,
    };
  },
});
</script>

<style>
</style>